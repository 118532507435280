import { Env } from "classes"

const REASONS: Record<string, any> = {
  CLIENT_REQUEST: "Cancelar SG por solicitud del cliente",
  PICKING_ERROR: "Cancelar SG por series no disponibles para pickear",
  SERIES_NOT_AVAILABLE: "Cancelar SG por series no disponibles",
  DATA_ERROR: "Cancelar SG por error de datos en la orden",
  SINISTER_ORDER: "Cancelar SG por siniestro",
  MAX_DELIVERY_ATTEMPT: "Cancelar SG por máximo intento de entrega",
  NOT_DELIVERY_PAYMENT: "Cancelar SG por no pago contra entrega",
  BIOMETRIC_FAILED: "Cancelar SG por validación biométrica fallida",
  NOT_PICKED_UP: "Cancelar SG por No Retiro",
  UNKNOW_REASON: "Motivo desconocido",
}

export const reason_cancel_body = (reason: string, sg: any, email: string) => {

  if (!REASONS[reason]) {
    return {
      entityId: sg?.salesChannelId || null,
      action: "CANCEL",
      custom: {
        reason: "UNKNOWN",
        description: "Motivo no reconocido",
        executer: {
          name: email || "Desconocido",
          id: "Omnix Store",
        },
      },
    }
  }

  return {
    entityId: sg?.salesChannelId,
    action: "CANCEL",
    custom: {
      reason,
      description: REASONS[reason] || "Motivo no especificado",
      executer: {
        name: email,
        id: "Omnix Store",
      },
    },
  }
}

const REASON_DESCRIPTIONS: Record<string, string> = {
  CUSTOMER_REJECTS: "Cancelar SG Cliente rechaza",
  DANGEROUS_AREA: "Cancelar SG Zona peligrosa",
  NO_COVERAGE: "Cancelar SG No cobertura",
  UNCONTACTED_MEETING_POINT: "Cancelar SG Punto de encuentro no contactado",
  EXPIRED_DNI: "Cancelar SG DNI caduco",
  PILOT_DOES_NOT_COMPLY: "Cancelar SG No cumple piloto",
  ABDCP_DEMANDABLE_DEBT: "Cancelar SG ABDCP deuda exigible",
  ABDCP_REJECTION: "Cancelar SG ABDCP rechazo",
  ABDCP_SERVICE_SUSPENDED: "Cancelar SG ABDCP servicio suspendido",
  CHANNEL_REQUEST: "Cancelar SG Solicitud canal",
  POSSIBLE_FRAUD: "Cancelar SG Posible fraude",
  WORN_FOOTPRINTS: "Cancelar SG Huellas desgastadas",
  NOT_VALID_SIEBEL: "Cancelar SG No válido Siebel",
  CU_REJECTION: "Cancelar SG Rechazo CU",
  TDE_REJECTION: "Cancelar SG Rechazo TDE",
  INVALID_PIN: "Cancelar SG PIN porta no válido",
  CUSTOMER_REJECTION_PIN: "Cancelar SG PIN porta cliente rechaza",
  BIOMETRIC_FAILED_CUSTOMER: "Cancelar SG Biometría fallida de cliente",
  BIOMETRIC_FAILED_COURIER: "Cancelar SG Biometría fallida de courier",
  PORTABILITY_PIN_NOT_SUCCESSFUL: "Cancelar SG Pin de portabilidad no exitoso",
}

export const reason_cancel_delivered_source_body = (reason: string, sg: any, email: string) => {
  return {
    entityId: sg?.salesChannelId,
    action: "CANCEL",
    custom: {
      reason,
      description: REASON_DESCRIPTIONS[reason] || "Motivo no especificado",
      executer: {
        name: email,
        id: "Omnix Store",
      },
    },
  }
}

const get_cancel_reasons = (current_status: any, shippingtype: string) => {
  const statuses = Env.theme.entel.sg[shippingtype.toUpperCase()].status

  const cancel_reasons = {
    [statuses[0]]: ["DATA_ERROR", "CLIENT_REQUEST"],
    [statuses[1]]: ["DATA_ERROR", "PICKING_ERROR", "CLIENT_REQUEST"],
    [statuses[2]]: ["CLIENT_REQUEST", "DATA_ERROR", "PICKING_ERROR"],
    [statuses[3]]: ["SERIES_NOT_AVAILABLE"],
    [statuses[8]]: ["SINISTER_ORDER"],
    [statuses[20]]: ["SINISTER_ORDER"],
    [statuses[9]]: [
      "SINISTER_ORDER",
      "MAX_DELIVERY_ATTEMPT",
      "NOT_DELIVERY_PAYMENT",
      "BIOMETRIC_FAILED",
      "CUSTOMER_REJECTS",
      "DANGEROUS_AREA",
      "NO_COVERAGE",
      "UNCONTACTED_MEETING_POINT",
      "EXPIRED_DNI",
      "PILOT_DOES_NOT_COMPLY",
      "ABDCP_DEMANDABLE_DEBT",
      "ABDCP_REJECTION",
      "ABDCP_SERVICE_SUSPENDED",
      "CHANNEL_REQUEST",
      "POSSIBLE_FRAUD",
      "WORN_FOOTPRINTS",
      "NOT_VALID_SIEBEL",
      "CU_REJECTION",
      "TDE_REJECTION",
      "INVALID_PIN",
      "CUSTOMER_REJECTION_PIN",
      "BIOMETRIC_FAILED_CUSTOMER",
      "BIOMETRIC_FAILED_COURIER",
      "PORTABILITY_PIN_NOT_SUCCESSFUL",
    ],
  }

  return (
    cancel_reasons[current_status?.toUpperCase()]?.map((reason) => ({
      label: REASON_DESCRIPTIONS[reason] || REASONS[reason] || reason,
      value: reason,
    })) || []
  )
}

export default get_cancel_reasons
