import translatesObj from "overrides/theme/entelperu/base/translates/translation.json"
import { Env } from "classes"

let statusses: any = translatesObj

export const getStatusTranslateValue = (statusKey: string, _shippingType: string) => {
  if (statusses.STATUS[_shippingType] && statusses.STATUS[_shippingType][statusKey]) {
    return statusses.STATUS[_shippingType][statusKey]
  }
  if (statusses.STATUS.default && statusses.STATUS.default[statusKey]) {
    return statusses.STATUS.default[statusKey]
  }
  return statusKey
}

export const getActionTranslateValue = (actionKey: string) => {
  if (statusses[actionKey]) {
    return statusses[actionKey]
  }
  return actionKey
}

export const StatusCheckerShowActions = (currentState: string, shipping_type: string) => {
  const sg_status_entelperu = Env.theme.entelperu.sg[shipping_type]?.status || []

  const state_base_reference = sg_status_entelperu.find(
    (state: string) => state === "PICKING_COMPLETED_PENDING_PACKAGE",
  )

  const compareStatus = (inputStatus: string, referenceStatus: string): boolean => {
    const inputIndex = sg_status_entelperu.indexOf(inputStatus)
    const referenceIndex = sg_status_entelperu.indexOf(referenceStatus)

    if (inputIndex === -1 || referenceIndex === -1) {
      // return "Uno o ambos estados no existen en la lista."
      return false
    }

    if (inputIndex < referenceIndex) {
      // return `${inputStatus} está antes que ${referenceStatus}`
      return false
    } else if (inputIndex > referenceIndex) {
      // return `${inputStatus} está después que ${referenceStatus}`
      return true
    } else {
      // return `${inputStatus} está en la misma posición que ${referenceStatus}`
      return true
    }
  }
  return compareStatus(currentState, state_base_reference)
}
